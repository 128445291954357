<template>
  <div id="bottomLeft2"  @click="projectTime">
    <!-- <div class=""> -->
       <h3 class="title">项目用时</h3>
      <div>
        <BottomLeftChart />
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import BottomLeftChart from '@/components/echart/bottom/bottomLeftChart'
export default {
  components: {
    BottomLeftChart
  },
  methods: {
  projectTime(){
    this.$router.push('erji/projectTime') 
  }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 400px;
$box-width: 720px;
#bottomLeft2 {
  padding: 4px 4px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  // .bg-color-black {
  //   height: $box-height - 35px;
  //   border-radius: 10px;
  // }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
