<template>
  <div id="centerRight1">
    <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['<span style="color:#06b2f1;font-weight:bold">班级</span>', 
        '<span style="color:#06b2f1;font-weight:bold">男生</span>', 
        '<span style="color:#06b2f1;font-weight:bold">女生</span>'],
        data: [
          ['一年级一班', '34', "20"],
          ['一年级二班', '23', "31"],
          ['二年级一班', '26', "24"],
          ['三年级一班', '33', "32"],
          ['三年级二班', '26', "29"],
          ['四年级一班', '31', "22"],
          ['五年级一班', '20', "28"],
          ['五年级二班', '24', "36"],
          ['六年级一班', '34', "26"],
          ['六年级二班', '28', "32"],
        ],
        rowNum: 6, //表格行数
        headerHeight: 38,
        headerBGC: 'rgba(255,255,255,.1)', //表头
        oddRowBGC: '#041541', //奇数行
        evenRowBGC: 'rgba(255,255,255,.1)', //偶数行
        align: ['center','center'],
        columnWidth: [130],
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 234px;
$box-width: 322px;
#centerRight1 {
  // padding: 16px;
  // padding-top: 20px;
  margin-top:50px;
  margin-right: 30px;
  height: $box-height;
  width: $box-width;
  overflow: hidden;
  .bg-color-black {
    // height: $box-height - 30px;
    // border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 270px;
      height: 340px;
      color: #d4eaf6;
      ::v-deep .header-item{
        border-bottom: 1px solid #384668 !important;
      }
      ::v-deep .row-item{
        height: 34px !important;
        line-height: 34px !important;
      }
      
    }
  }
}
</style>
