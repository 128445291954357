<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        category: [
         '跳远', '50米跑', '仰卧起坐', '一分钟跳绳'
        ],
        lineData: [36,53,78,92,104,120],
       barData1: [60,53,58,46,36,30],
        barData2: [135,112,96,63,52,40],
        barData3: [98,73,69,48,41,36],
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
  
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
</style>