<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="400px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
      // 定义颜色
      colorList: {
        linearYtoG: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: '#f5b44d'
            },
            {
              offset: 1,
              color: '#28f8de'
            }
          ]
        },
        linearGtoB: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#43dfa2'
            },
            {
              offset: 1,
              color: '#28f8de'
            }
          ]
        },
        linearBtoG: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#1c98e8'
            },
            {
              offset: 1,
              color: '#28f8de'
            }
          ]
        },
        areaBtoG: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(35,184,210,.2)'
            },
            {
              offset: 1,
              color: 'rgba(35,184,210,0)'
            }
          ]
        }
      }
    }
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
    formatter: '{a} <br/>{b} : {c}%'
  },
  series: [
    {
      name: '肺活量',
      type: 'gauge',
      min: 0,
      max: 3000,
      radius: '40%',
      center: ['20%', '16%'],
      progress: {
        show: true,
        width: 6,
        itemStyle: {
          color: '#4baefd'
        }
      },
      axisLine: {
        lineStyle: {
          width: 6,
          color: [[1, '#0b275b']]
        }
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },

      splitLine: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },
      label: {
        show: false
      },
      detail: {
        fontSize: 14,
        color: '#fff',
        offsetCenter: [0, '60%'],
        formatter: '{value} ml',
      },
      title: {
        offsetCenter: ['0%', '110%'],
        fontSize: 16,
        color: '#0cb2fe'
      },
      pointer: {
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        show: true,
        length: '65%',
        width: 10,
        itemStyle: {
          color: '#eec562'
        }
      },
      data: newData.pData1
    },
	{
      name: '50米跑',
      type: 'gauge',
      min: 6,
      max: 23,
      radius: '40%',
      center: ['72%', '16%'],
      z: 6,
      progress: {
        show: true,
        width: 6,
        itemStyle: {
          color: '#4baefd'
        }
      },
      axisLine: {
        lineStyle: {
          width: 6,
          color: [[1, '#0b275b']]
        }
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },

      splitLine: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },
      label: {
        show: false
      },
      detail: {
        fontSize: 16,
        color: '#fff',
        offsetCenter: [0, '60%'],
         formatter: '{value} s',
      },
      title: {
        offsetCenter: ['0%', '110%'],
        fontSize: 16,
        color: '#0cb2fe'
      },
      pointer: {
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        show: true,
        length: '65%',
        width: 10,
        itemStyle: {
          color: '#eec562'
        }
      },
      data: newData.pData2
    },
	{
      name: '坐位体前屈',
      type: 'gauge',
      min: 0,
      max: 30,
      radius: '40%',
      center: ['20%', '47%'],
      z: 6,
      progress: {
        show: true,
        width: 6,
        itemStyle: {
          color: '#4baefd'
        }
      },
      axisLine: {
        lineStyle: {
          width: 6,
          color: [[1, '#0b275b']]
        }
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },

      splitLine: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },
      label: {
        show: false
      },
      detail: {
        fontSize: 16,
        color: '#fff',
        offsetCenter: [0, '60%'],
		formatter: '{value} cm',
      },
      title: {
        offsetCenter: ['0%', '110%'],
        fontSize: 16,
        color: '#0cb2fe'
      },
      pointer: {
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        show: true,
        length: '65%',
        width: 10,
        itemStyle: {
          color: '#eec562'
        }
      },
      data:newData.pData3
    },
	{
      name: '1分钟跳绳',
      type: 'gauge',
      min: 0,
      max: 200,
      radius: '40%',
      center: ['72%', '47%'],
      z: 6,
      progress: {
        show: true,
        width: 6,
        itemStyle: {
          color: '#4baefd'
        }
      },
      axisLine: {
        lineStyle: {
          width: 6,
          color: [[1, '#0b275b']]
        }
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },

      splitLine: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },
      label: {
        show: false
      },
      detail: {
        fontSize: 16,
        color: '#fff',
        offsetCenter: [0, '60%'],
		formatter: '{value} 个',
      },
      title: {
        offsetCenter: ['0%', '110%'],
        fontSize: 16,
        color: '#0cb2fe'
      },
      pointer: {
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        show: true,
        length: '65%',
        width: 10,
        itemStyle: {
          color: '#eec562'
        }
      },
      data:newData.pData4
    },
	{
      name: '50*8往返跑',
      type: 'gauge',
      min: 0,
      max:5,
      radius: '40%',
      center: ['20%', '77%'],
      z: 6,
      progress: {
        show: true,
        width: 6,
        itemStyle: {
          color: '#4baefd'
        }
      },
      axisLine: {
        lineStyle: {
          width: 6,
          color: [[1, '#0b275b']]
        }
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },

      splitLine: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },
      label: {
        show: false
      },
      detail: {
        fontSize: 16,
        color: '#fff',
        offsetCenter: [0, '60%'],
		formatter: '{value} min',
      },
      title: {
        offsetCenter: ['0%', '110%'],
        fontSize: 16,
        color: '#0cb2fe'
      },
      pointer: {
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        show: true,
        length: '65%',
        width: 10,
        itemStyle: {
          color: '#eec562'
        }
      },
      data: newData.pData5
    },
	{
      name: '立定跳远',
      type: 'gauge',
      min: 0,
      max: 3,
      radius: '40%',
      center: ['72%', '77%'],
      z: 6,
      progress: {
        show: true,
        width: 6,
        itemStyle: {
          color: '#4baefd'
        }
      },
      axisLine: {
        lineStyle: {
          width: 6,
          color: [[1, '#0b275b']]
        }
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },

      splitLine: {
        show: true,
        length: 6,
        splitNumber: 2,
        lineStyle: {
          color: '#4baefd',
          width: 2
        }
      },
      label: {
        show: false
      },
      detail: {
        fontSize: 16,
        color: '#fff',
        offsetCenter: [0, '60%'],
		formatter: '{value} 米',
      },
      title: {
        offsetCenter: ['0%', '110%'],
        fontSize: 16,
        color: '#0cb2fe'
      },
      pointer: {
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        show: true,
        length: '65%',
        width: 10,
        itemStyle: {
          color: '#eec562'
        }
      },
      data:newData.pData6
    },
    ]
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
