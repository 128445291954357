<template>
  <div>
    <Echart
      ref="pieChart"
      :options="options"
      id="centreLeft1Chart"
      height="260px"
      width="310px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
      highlightInterval: null,
      pieChart: null,
      pieDataLength: 4,
      higIndex: 0
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: {
    text: '男女师生占比',
    left: 'center',
    top: '34%',
    width: '200',
    textStyle: {
      color: '#ffffff',
      fontSize: 16,
      fontWeight:400,
    }
  },
          color: ['#f8b62d', '#52ffff', '#f53f8c', '#28b1ff'],
          tooltip: {
            trigger: "item",
            formatter: '{b} : {c}' + ' %',
            backgroundColor:'rgba(10, 33, 75, 0.8)',
   
    borderWidth:'1',
    borderColor:'#f4bc30',
    textStyle:{
      color:'#ffffff',
      }
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: {
               top: 'bottom',
	left: 'center',
    width: '200',
	itemGap: 10,
	textStyle:{
		color:"#ffffff"
	}
          },
          series: [
            {
              type: "pie",
              radius: [50, 80],
              center: ["50%", "38%"],
              avoidLabelOverlap: false,
               roseType: 'area',
              itemStyle: {
        borderColor: '#041640',
        borderWidth: 4
      },
      label: {
        normal: {
          show: true,
          formatter: '{c}%',
          color:'#fff',
        }
      },
       labelLine: {
		lineStyle: {
             color: "#fff"  // 改变标示线的颜色
          }
      },
              data: newData.seriesData
            }
          ]
        };



      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
   this.pieChart = this.$refs.pieChart.chart
    this.intervalHandler()
    // 绑定 mouseover 事件
    this.pieChart.on('mouseover', (params) => {
      clearInterval(this.highlightInterval)

      // 清除高亮（每次高亮前必须重复该操作，否则会叠加）
      this.pieChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })

      this.pieChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      })
      this.pieChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      });
     

      // 鼠标移出后从该处继续高亮
      this.higIndex = params.dataIndex
    })

    // 绑定 mouseout 事件
    this.pieChart.on('mouseout', () => {
      this.intervalHandler()
    })
  },
  beforeDestroy() {
   this.highlightInterval && clearInterval(this.highlightInterval)
  },
  methods: {
   intervalHandler() {
      this.pieChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })

      this.pieChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.higIndex
      })
      
      this.pieChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.higIndex
      });

      this.highlightInterval = setInterval(() => {
        this.pieChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0
        })
  
        this.pieDataLength === this.higIndex + 1 ? (this.higIndex = 0) : (this.higIndex++)

        this.pieChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.higIndex
        })

        this.pieChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.higIndex
      });

      }, 5000)
    }
  }
};
</script>

<style lang="scss" scoped>
</style>