<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
         xData: ['#f8b62d', '#52ffff', '#f53f8c', '#28b1ff'],
        seriesData: [
          { name: '女生     ', value: 68 },
          { name: '男生     ', value:76 },
          { name: '女体育老师', value:16 },
          { name: '男体育老师', value: 25 }
        ]
      },
      //pieOptions: PIE_OPTIONS,
      // highlightInterval: null,
      // pieChart: null,
      // pieDataLength: 5,
      // higIndex: 0
    }
  },
  components: {
    Chart,
  },
  mounted () {
   
  },
  beforeDestroy() {
   
  },
  methods: {
   
  }
  
}
</script>

<style lang="scss" scoped>
</style>