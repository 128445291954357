<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        indicatorData: [19,17.8,19.9,23.4,22,16],
        indicator2Data:[65,78,89,59,88,93],
  

      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
    
  },
  methods: {
   
  }
}
</script>