<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <!-- <div class="d-flex jc-center topTitle">
            <div class="title">
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
         
        </div> -->
        <!-- 头部 -->
         <div class="top-header">
            <dv-decoration-8 class="header-left-decoration" />
            <dv-decoration-5 class="header-center-decoration" dur="3" />
            <dv-decoration-8 class="header-right-decoration" :reverse="true" />
            <div class="center-title">经纶学院体育运动数据</div>
          </div>
<span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >

        <div class="body-box">
          <!-- 第三行数据 -->

          <div class="content-box">
            <div>
              <dv-border-box-12>
                <centerLeft1 />
              </dv-border-box-12>
            </div>
           
            <!-- 中间 -->
            <div>
              <center />
            </div>
            <!-- 中间 -->
            <div>
                <centerRight2 />
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="bottom-box">
            <div>
              <dv-border-box-12>
               <bottomLeft1 />
              </dv-border-box-12>
            </div>
             <div>
              <dv-border-box-12>
               <bottomLeft2 />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
               <bottomRight1 />
              </dv-border-box-12>
            </div>
            
               <bottomRight2 />
             
       
          </div>
           
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft1 from './centerLeft1'
// import centerLeft2 from './centerLeft2'
// import centerRight1 from './centerRight1'
import centerRight2 from './centerRight2'
import center from './center'
import bottomLeft1 from './bottomLeft1'
import bottomLeft2 from './bottomLeft2'
import bottomRight1 from './bottomRight1'
import bottomRight2 from './bottomRight2'

export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000']
    }
  },
  components: {
    centerLeft1,
    // centerLeft2,
    // centerRight1,
     centerRight2,
    center,
    bottomLeft1,
    bottomLeft2,
    bottomRight1,
    bottomRight2
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>
