<template>
  <div id="centerLeft1" @click="pageErji">
    <div class="group">
      <div class="d-flex pt-2 pl-2">
        <h3 class="title fixed1">当前数据动态</h3>
		<!-- <dv-decoration-1 class="dh" :color="['#3e527f', '#00deec']" style="width:150px;height:30px;" /> -->
					<img src="../assets/dt.png" class="dtLeft_Img" alt="">
					<div class="dtLeft_Con">

						<div class="classdt" v-for="(item,index) in titleItem" :key="index">
							<img :src=item.img alt="" :class=item.clname>
							<div class="class_Info"  :class="['class_Info' + item.number,{'show': firstSwitchArr[index]}]" >
								<h5>{{item.title}}</h5>
								<ul>
									<li>
										<span>时间：</span>{{ dateYear }} {{ dateDay }}
									</li>
									<li>
										<span>平均班级数量：</span>{{item.bjnum}}个
									</li>
									<li>
										<span>平均学习时长：</span>{{item.sctime}}小时
									</li>
									<li>
										<span>平均运动时长：</span>{{item.ydtime}}小时
									</li>
									<li>
										<span>平均身高体重：</span>{{item.sg}}cm / {{item.tz}}kg
									</li>
								</ul>
							</div>
						</div>
						
					</div>
       
      </div>
     
      
    </div>
  </div>
</template>

<script>
import { formatTime } from '../utils/index.js'
export default {
  data() {
    return {
	timer: null,
	timing: null,index:0,
      dateDay: null,
      dateYear: null,
      firstSwitchArr: [false, false, false, false, false], 
      titleItem: [
        {
			number: '1',
			clname:'one',
			title: '一年级',
			bjnum:'5',
			sctime:'6',
			ydtime:'8',
			sg:'110',
			tz:'40',
			img:require('../assets/1.png')
        },
		{
			number: '2',
			clname:'two',
			title: '二年级',
			bjnum:'6',
			sctime:'6',
			ydtime:'6',
			sg:'120',
			tz:'42',
			img:require('../assets/2.png')
        }, {
			number: '3',
			clname:'three',
			title: '三年级',
			bjnum:'4',
			sctime:'8',
			ydtime:'4',
			sg:'124',
			tz:'46',
			img:require('../assets/3.png')
        }, {
			number: '4',
			clname:'four',
			title: '四年级',
			bjnum:'5',
			sctime:'9',
			ydtime:'3',
			sg:'140',
			tz:'48',
			img:require('../assets/4.png')
        }, {
			number: '5',
			clname:'five',
			title: '五年级',
			bjnum:'5',
			sctime:'10',
			ydtime:'2',
			sg:'153',
			tz:'52',
			img:require('../assets/5.png')
        }, {
			number: '6',
			clname:'six',
			title: '六年级',
			bjnum:'5',
			sctime:'12',
			ydtime:'2',
			sg:'160',
			tz:'56',
			img:require('../assets/6.png')
        }
        
        
      ],
    }
  },
  mounted() {
    this.timeFn();
	this.timeFn1();
  },
  methods: {
    timeFn() {
		this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
      }, 1000)
    },
	timeFn1(){
		this.timer= setInterval(()=>{
			if(this.index>5){
				this.index = 0 
			}
			this.firstSwitchArr=[false, false, false, false, false]
			this.$set(this.firstSwitchArr, this.index, !this.firstSwitchArr[this.index]) 
			this.index ++;   
		}, 10000);
	},	
  pageErji(){
    this.$router.push('erji') 
  }
  },

}
</script>

<style lang="scss" scoped>
$box-width: 802px;
$box-height: 540px;

#centerLeft1 {
  padding: 4px 4px;
  height: $box-height;
  width: $box-width;
  border-radius: 4px;
  position: relative;
  .group {
    height: $box-height - 16px;
    border-radius: 10px;
      .title{
   
     position:absolute;
    left: 0;
    top: 0;
}
.dh{
	position:absolute;
    left: 150px;
    top: 14px;
}
.dtLeft_Img{
    width: 100%;
    height: 100%;
    display: block;
}
  }

.dtLeft_Con{
  .classdt{
    cursor: pointer;
    img{
      position: absolute;
      display: block;
    }
    .one{
       right: 72px;
    top: 106px;
}
.two{
    left:70px;
    top: 150px;
}
.three{
    right: 72px;
    top: 196px;
}
.four{
    left:70px;
    top: 242px;
}
.five{
    right: 72px;
    bottom: 212px;
}
.six{
    left:70px;
    bottom: 166px;
}

.class_Info{
    position: absolute;
    right: 8%;
    top: 29%;
    width: 266px;
    /* height: 153px; */
    background: rgba(10, 33, 75, .8);
    border: 1px solid #f4bc30;
    padding: 10px;
    display: none;
    z-index: 999;
    h5{
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
    color: #fec839;
}
li{
    font-size: 14px;
    color: #fff;
    line-height: 22px;
    span{
    display: inline-block;
    width: 112px;
    color: #c5d9ff;
}
}
}
.show{
  display: block;
}
.class_Info::after{
    content: '';
    background: url(../assets/sj.png) no-repeat;
    right: 130px;
    top: -11px;
    width: 17px;
    height: 12px;
    position: absolute;
    display: block;
}
.class_Info2{
    left: 82px;
    top: 200px;
}
.class_Info3{
    right: 8%;
    top: 44%;
}
.class_Info4{
    left: 82px;
    top: 292px;
}
.class_Info5{
    right: 8%;
    top: 60%;
}
.class_Info6{
    left: 82px;
    top: 380px;
}
}
.classdt:hover .class_Info{
display: block;
}

}
 
}
</style>
