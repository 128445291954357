<template>
  <div>
    <Echart
    ref="pieChart"
      :options="options"
      id="centreRight2Chart1"
      height="200px"
      width="310px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
import * as echarts from 'echarts'
export default {
  data () {
    return {
      options: {},
      highlightInterval: null,
      pieChart: null,
      pieDataLength: 6,
      higIndex: 0
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        // 固定样式数据
      
// indicator: newData.indicatorData,
        this.options = {

 tooltip: {
    trigger: 'axis',
    backgroundColor:'rgba(10, 33, 75, 0.8)',
    color:'#ffffff',
    borderWidth:'1',
    borderColor:'#f4bc30',
    textStyle:{
      color:'#ffffff',
      }
  },
  color:['#46abda','#d339f0'],  
  legend: {
    data: ['BMI', '成绩'],
    x: 'right',
    y:16,
    orient: 'vertical',
	textStyle:{
		color:"#ffffff"
	},
	icon:'rect'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },

  xAxis: {
    type: 'category',
    boundaryGap: false,
	
	axisLine:{
		lineStyle: {
			type: 'solid',
			color: "#9abtreff",
			width:'1'
		}
	},
	
	axisTick:{
		show:false,
		
	},
    data: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级']
  },
  yAxis: {
    type: 'value',
	min: 0,
      max: 100,
      interval: 20,
	axisLine:{
		lineStyle: {
			type: 'solid',
			color: "#9abeff",
		}
	},
	splitLine:{
		lineStyle: {
			color:'#102661',
		},		
	},
  },
  series: [
    {
      name: 'BMI',
      type: 'line',
      smooth: true,
      showSymbol: false,
      lineStyle: {
        color: '#46abda',
        width: 5
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(59, 163, 215)'
          },
          {
            offset: 1,
            color: 'rgb(4, 21, 66)'
          }
        ])
      },
      data: newData.indicatorData
    },
    {
      name: '成绩',
      type: 'line',
      smooth: true,
      showSymbol: false,

      lineStyle: {
        color: '#d339f0',
        width: 5
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(206, 57, 239)'
          },
          {
            offset: 1,
            color: 'rgb(4, 21, 66)'
          }
        ])
      },
      data: newData.indicator2Data
    }
  ]

         
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
   this.pieChart = this.$refs.pieChart.chart
    this.intervalHandler()
    // 绑定 mouseover 事件
    this.pieChart.on('mouseover', (params) => {
      clearInterval(this.highlightInterval)

      // 清除高亮（每次高亮前必须重复该操作，否则会叠加）
      this.pieChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })

      this.pieChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      })
      this.pieChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      });
     

      // 鼠标移出后从该处继续高亮
      this.higIndex = params.dataIndex
    })

    // 绑定 mouseout 事件
    this.pieChart.on('mouseout', () => {
      this.intervalHandler()
    })
  },
  beforeDestroy() {
   this.highlightInterval && clearInterval(this.highlightInterval)
  },
  methods: {
   intervalHandler() {
      this.pieChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })

      this.pieChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.higIndex
      })
      
      this.pieChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.higIndex
      });

      this.highlightInterval = setInterval(() => {
        this.pieChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0
        })
  
        this.pieDataLength === this.higIndex + 1 ? (this.higIndex = 0) : (this.higIndex++)

        this.pieChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.higIndex
        })

        this.pieChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.higIndex
      });

      }, 5000)
    }
  }
};
</script>