<template>
  <div>
  
    <Echart
     ref="pieChart"
      :options="options"
      id="bottomLeftChart"
      height="340px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
      highlightInterval: null,
      pieChart: null,
      pieDataLength: 6,
      higIndex: 0
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
        tooltip: {
    trigger: 'axis',
    backgroundColor:'rgba(10, 33, 75, 0.8)',
    color:'#ffffff',
    borderWidth:'1',
    borderColor:'#f4bc30',
    textStyle:{
      color:'#ffffff',
      }

    // axisPointer: {
    //   type: 'cross',
    //   crossStyle: {
    //     color: '#999'
    //   }
    // }
  },
  color:['#12cfd8','#4ebdff','#e3c71e','#e3c71e'],   
  legend: {
    data: newData.category,
	left: 'right',
	textStyle:{
		color:"#ffffff"
	},
  },
  xAxis: [
    {
      type: 'category',
      data: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
      axisPointer: {
        type: 'shadow'
      },
      axisLine:{
        lineStyle: {
          type: 'solid',
          color: "#9abtreff",
          width:'1'
          }
	},
	axisTick:{
		show:false,
	},
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisLine:{
        lineStyle: {
          type: 'solid',
          color: "#9abeff",
          }
	},
	splitLine:{
		lineStyle: {
			color:'#102661',
		},		
	},
      min: 0,
      max: 200,
      interval: 50,
      axisLabel: {
        formatter: '{value} min'
      }
    },
    {
      type: 'value',
      axisLine:{
        lineStyle: {
          type: 'solid',
          color: "#9abeff",
          }
	},
	splitLine:{
		lineStyle: {
			color:'#102661',
		},		
	},
      min: 0,
      max: 200,
      interval: 50,
      axisLabel: {
        formatter: '{value} 个'
      }
    }
  ],
  series: [
    {
      name: '跳远',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' min';
        }
      },
      data:newData.barData1,
    },
    {
      name: '50米跑',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' min';
        }
      },
      data:newData.barData2,
    },
    {
      name: '仰卧起坐',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' min';
        }
      },
      data:newData.barData3,
    },
    {
      name: '一分钟跳绳',
      type: 'line',
      symbolSize:6,
      tooltip: {
        valueFormatter: function (value) {
          return value + '个';
        }
      },
      data: newData.lineData,
    }
  ]
        }
      },
      immediate: true,
      deep: true
    },
  },
  mounted () {
   this.pieChart = this.$refs.pieChart.chart
    this.intervalHandler()
    // 绑定 mouseover 事件
    this.pieChart.on('mouseover', (params) => {
      clearInterval(this.highlightInterval)

      // 清除高亮（每次高亮前必须重复该操作，否则会叠加）
      this.pieChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })

      this.pieChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      })
      this.pieChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: params.dataIndex
      });
     

      // 鼠标移出后从该处继续高亮
      this.higIndex = params.dataIndex
    })

    // 绑定 mouseout 事件
    this.pieChart.on('mouseout', () => {
      this.intervalHandler()
    })
  },
  beforeDestroy() {
   this.highlightInterval && clearInterval(this.highlightInterval)
  },
  methods: {
   intervalHandler() {
      this.pieChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })

      this.pieChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.higIndex
      })
      
      this.pieChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.higIndex
      });

      this.highlightInterval = setInterval(() => {
        this.pieChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0
        })
  
        this.pieDataLength === this.higIndex + 1 ? (this.higIndex = 0) : (this.higIndex++)

        this.pieChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.higIndex
        })

        this.pieChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.higIndex
      });

      }, 5000)
    }
  }
}
</script>