<template>
  <div id="centerRight2" >
      <div>
      <dv-border-box-13>
        <div class="qcsh" @click="equipmentSh">
						<h3 class="title">运动器材损耗</h3>
						<ul class="qcshCon">
							<li>
								<span><i class="color1">28</i>个</span>
								<em>跳绳</em>
							</li>
							<li>
								<span><i class="color2">34</i>个</span>
								<em>助跑器</em>
							</li>
							<li>
								<span><i>36</i>个</span>
								<em>体前屈器材</em>
							</li>
							<li>
								<span><i>60</i>个</span>
								<em>排球</em>
							</li>
							<li>
								<span><i>49</i>个</span>
								<em>毽子</em>
							</li>
							<li>
								<span><i>50</i>个</span>
								<em>沙包</em>
							</li>
						</ul>
					</div>

      </dv-border-box-13>
      </div>
      <div class="d-flex ai-center flex-column body-box">
        <div>
          <dv-border-box-12>
            <div  class="fxGroup" @click="bmiCj">
            <h3 class="title">BMI与成绩分析</h3>
            <div>
              <centerRight2Chart1 />
            </div>
            </div>
        
        </dv-border-box-12>
        </div>
      </div>
  </div>
</template>

<script>
import centerRight2Chart1 from '@/components/echart/centerRight/centerRightChart'

export default {
  components: { centerRight2Chart1 },
  methods: {
  equipmentSh(){
    this.$router.push('erji/equipmentSh') 
  },
  bmiCj(){
    this.$router.push('erji/bmiCj') 
  }
  }
}
</script>

<style lang="scss" scoped>
 $box-height: 540px;
  $box-width: 340px;
#centerRight2 {
 display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: $box-height;
  .title{
    padding: 20px 24px;
    font-size: 16px;
    color: #00fbff;
    text-align: left;
    font-weight: 400;
    letter-spacing: 2px;
}
  .bg-color-black {
    padding: 5px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
  }
  .qcsh{
    height: 298px;
    width: $box-width;
    .qcshCon{
// overflow: hidden;
display: flex;
flex-wrap: wrap;
li{
    // float: left;
    width: 50%;
    text-align: center;
    margin-bottom: 28px;
    color: #9abeff;
    span{
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
     i.color1{
    font-size: 30px;
    color: #ff6400;
    font-style: normal;
}
i{
  font-style: normal;
  display: inline-block;
  font-size: 24px;
}
i.color2{
    font-size: 30px;
    color: #e3c71e;
    
}
}
em{
    display: block;
    font-size: 14px;
    font-style: normal;
}
}
}
}


  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
width: 340px;
.title{
  position: absolute;
  left: 0;
  top: 0;
}
    .dv-cap-chart {
      width: 100%;
      height: 210px;
    }
  }
}
.fxGroup{
  width: 340px;
  height: 216px;
}
</style>
