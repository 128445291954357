<template>
  <div id="bottomLeft1" @click="projectInfo">
    <h3 class="title">项目信息</h3>
    <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['<span style="color:#06b2f1;font-weight:bold">项目</span>', 
        '<span style="color:#06b2f1;font-weight:bold">成绩</span>', 
        '<span style="color:#06b2f1;font-weight:bold">班级姓名</span>'],
        data: [
          ['50米跑', '<span style="color:#ff7200;font-weight:bold">30秒</span>', "三年一班贺信"],
          ['坐位体前屈', '<span style="color:#ff7200;font-weight:bold">23cm</span>', "六年四班肖潇"],
          ['一分钟跳绳', '<span style="color:#ff7200;font-weight:bold">126个</span>', "三年一班贺信"],
          ['仰卧起坐', '<span style="color:#fcff00;font-weight:bold">33个</span>', "五年三班赵晓旭"],
          ['立定跳远', '<span style="color:#fcff00;font-weight:bold">1.68米</span>', "五年一班吴小平"],
          ['800米跑', '<span style="color:#fcff00;font-weight:bold">2分20秒</span>', "六年三班张芳芳"],
          ['仰卧起坐', '<span style="color:#fcff00;font-weight:bold">33个</span>', "五年三班赵晓旭"],
          ['立定跳远', '<span style="color:#fcff00;font-weight:bold">1.68米</span>', "五年一班吴小平"],
          ['800米跑', '<span style="color:#fcff00;font-weight:bold">2分20秒</span>', "六年三班张芳芳"],
          ['仰卧起坐', '<span style="color:#fcff00;font-weight:bold">33个</span>', "五年三班赵晓旭"],
          ['立定跳远', '<span style="color:#fcff00;font-weight:bold">1.68米</span>', "五年一班吴小平"],
          ['800米跑', '<span style="color:#fcff00;font-weight:bold">2分20秒</span>', "六年三班张芳芳"],
        ],
        rowNum: 6, //表格行数
        headerHeight: 42,
        headerBGC: 'rgba(23,51,107,.7)', //表头
        oddRowBGC:'rgba(8,23,62,.5)',
        evenRowBGC:'rgba(8,23,62,.5)',
        align: ['center'],
        columnWidth: [130],
      }
    }
  },
methods: {
  projectInfo(){
    this.$router.push('erji/projectInfo') 
  }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 400px;
$box-width: 460px;
#bottomLeft1 {
  // padding: 16px;
  // padding-top: 20px;
  // margin-top:50px;
  // margin-right: 30px;
  height: $box-height;
  width: $box-width;
  overflow: hidden;

  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 400px;
      height: 340px;
      color: #9abeff;
      text-align: center;
      // .header{
      //   color: red;
      //   text-align: center;
      // }
    }
  }
}
// ::v-deep .dv-scr-board .header{
//   color: red !important;
// }
</style>
