<template>
  <div id="bottomRight2" @click="projectInfo">
    <div class="">
      <div>
        <BottomRightChart />
      </div>
    </div>
  </div>
</template>

<script>
import BottomRightChart from "@/components/echart/bottom/bottomRightChart";
export default {
  components: {
    BottomRightChart
  },
methods: {
  projectInfo(){
    this.$router.push('erji/projectInfo') 
  }
  }
};
</script>

<style lang="scss" class>
$box-height: 400px;
$box-width: 224px;
#bottomRight2 {
  // padding: 14px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  // .bg-color-black {
  //   height: $box-height - 30px;
  //   border-radius: 10px;
  // }
 
  //下滑线动态
  // .decoration2 {
  //   position: absolute;
  //   right: 0.125rem;
  // }
  // .chart-box {
  //   margin-top: 16px;
  //   width: 170px;
  //   height: 170px;
  //   .active-ring-name {
  //     padding-top: 10px;
  //   }
  // }
}
</style>