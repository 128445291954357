<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        pData1: [{ value: 1000, name: '肺活量' }],
        pData2: [{ value: 12, name: '50米跑' }],
        pData3: [{ value: 25, name: '坐位体前屈' }],
        pData4: [{ value: 140, name: '1跳绳跳绳' }],
        pData5: [{ value: 1.3, name: '50*8往返跑' }],
        pData6: [{ value: 2.07, name: '立定跳远' }]
      }
    }
  },
  components: {
    Chart
  },
  mounted () {
  },
  methods: {
  }
  }
</script>