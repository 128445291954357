<template>
  <div id="bottomRight1" @click="projectCj">
    <div class="">
     <h3 class="title">运动项目历年优秀成绩数量</h3>
      <div>
        <ul class="cjsl_Con">
						<li>
							<span>一分钟跳绳</span>
							<div class="cjsl_ConInfo">
								<div class="line">
									<div class="linesj" style="width:100%"></div>
								</div>
								<div class="cjsl_ConText">
									<span>168个</span>/<span>2020年</span>
								</div>
							</div>
						</li>
						<li>
							<span>坐位体前屈</span>
							<div class="cjsl_ConInfo">
								<div class="line">
									<div class="linesj" style="width:60%"></div>
								</div>
								<div class="cjsl_ConText">
									<span>20厘米</span>/<span>2021年</span>
								</div>
							</div>
						</li>
						<li>
							<span>立定跳远</span>
							<div class="cjsl_ConInfo">
								<div class="line">
									<div class="linesj" style="width:50%"></div>
								</div>
								<div class="cjsl_ConText">
									<span>1.6米</span>/<span>2022年</span>
								</div>
							</div>
						</li>
						<li>
							<span>50米跑</span>
							<div class="cjsl_ConInfo">
								<div class="line">
									<div class="linesj" style="width:80%"></div>
								</div>
								<div class="cjsl_ConText">
									<span>38秒</span>/<span>2022年</span>
								</div>
							</div>
						</li>
						<li>
							<span>800米跑</span>
							<div class="cjsl_ConInfo">
								<div class="line">
									<div class="linesj" style="width:70%"></div>
								</div>
								<div class="cjsl_ConText">
									<span>2分40秒</span>/<span>2020年</span>
								</div>
							</div>
						</li>
					</ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {

methods: {
  projectCj(){
    this.$router.push('erji/ProjectCj') 
  }
  }
}
</script>

<style lang="scss" class>
$box-height: 400px;
$box-width: 454px;
#bottomRight1 {
  padding: 4px 4px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  // .bg-color-black {
  //   height: $box-height - 30px;
  //   border-radius: 10px;
  // }
  .text {
    color: #c3cbde;
  }
  .cjsl_Con{
padding:0 20px;
li{
    margin-bottom: 18px;
    >span{
    display: block;
    font-size: 14px;
    color: #a8e1f9;
    margin-bottom: 6px;
}
}
li:last-child{
margin-bottom: 0;
}
}

.cjsl_ConInfo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.line{
    width: 66%;
    margin-right: 16px;
    
}
.linesj{
    height: 13px;
    background: linear-gradient(to right, #228af7 , #4db5fb);
   
    border-radius: 20px;
}
.cjsl_ConText{
    font-size:16px;
    color: #ffe110;
    font-weight: bold;
}
}
</style>