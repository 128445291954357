<template>
  <div id="center">
    <div>
    <dv-border-box-12>
    <div class="topGroup">
      <h3 class="title">年级运动分析</h3>
    <div class="up">
      <div
        class="item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <div class="topGroup_list">
          <dv-digital-flop
            class="dv-dig-flop"
            :config="item.number"
          />
          <div class="unit">{{ item.unit }}</div>
        </div>
        <p class="colorBlue">{{ item.title }}</p>
      </div>
    </div>
    </div>
    </dv-border-box-12>
    </div>
    <div>
    <dv-border-box-12>
    <div class="down" @click="GradeInfo">
      <div class="njxx">
        <h3 class="title">年级信息</h3>
        <div>
          <CenterLeft1Chart />
        </div>
      </div>
      <div>
         <centerRight1 />
      </div>
    </div>
    </dv-border-box-12>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
import centerRight1 from './centerRight1'
//import CenterChart from '@/components/echart/center/centerChartRate'

export default {
  components: {
    CenterLeft1Chart,
    centerRight1,
  },
  data() {
    return {
      titleItem: [
        {
          title: '每天运动平均时长',
          number: {
            number: [122],
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 22
            }
          },
          unit:'小时'
        },
        {
          title: '男生运动平均时长',
          number: {
            number: [18],
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 22
            }
          },
          unit:'小时'
        },
        {
          title: '女生运动平均时长',
          number: {
            number: [2],
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 22
            }
          },
          unit:'小时'
        },
        {
          title: '器材使用数量',
          number: {
            number: [14],
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 22
            }
          },
          unit:'个'
        },
         {
          title: '器材报废数量',
          number: {
            number: [257],
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 22
            }
          },
          unit:'个'
        },
        
      ],
     
      
      
    }
  },
methods: {
  GradeInfo(){
    this.$router.push('erji/GradeInfo') 
  }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 720px;
$box-height: 540px;
.title {
      padding: 20px 24px;
      font-size: 16px;
      color: #00fbff;
      text-align: left;
      font-weight: 400;
      letter-spacing: 2px;
  }
#center {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: $box-height;
  .topGroup{
    width: $box-width;
    height: 166px;
    // background: rgba(8, 23, 62, .5);
      
  .up {
    width: $box-width;
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    color: #9abeff;
    
    .item {
      // border-radius: 6px;
   float: left;
       margin-top: 16px;
      text-align: center;
      // width: 32%;
      // height: 70px;
      .topGroup_list{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 144px;
        margin-bottom: 8px;
        color: #9abeff;
        .dv-dig-flop {
        width: initial;
        height: 28px;
        text-align: right;
        font-size: 14px;
       font-weight: bold;
      }
       .unit {
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
  }
      }
      
    }
    >p{
      font-size: 14px;
    }
  }
  }
  .down {
    // background: rgba(8, 23, 62, .5);
    padding: 6px 4px;
    padding-bottom: 0;
    width: $box-width;
    display: flex;
    height: 348px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
